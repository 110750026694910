import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const [userType, setUserType] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const location = useLocation(); // Track URL changes

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch('http://localhost/ci3/api/api/profile', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Profile data:", result.profile.IS_ADMIN);

                if (result.status) {
                    setUserType(result.profile.IS_ADMIN || '');
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, [location]); // Dependency on location ensures the effect runs on URL change

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/dashboard" className="brand-link">
                <img
                    src="http://183.82.10.144:40407/bill_remainder/assets/favicon.png"
                    style={{ width: '30px', height: '30px', opacity: 0.8 }}
                    className="brand-image img-circle elevation-3"
                    alt="Brand Logo"
                />
                <span className="brand-text font-weight-light">Bill Remainder's</span>
            </Link>

            <div className="sidebar">
                <div className="form-inline">
                    <div className="input-group" data-widget="sidebar-search">
                        <input
                            className="form-control form-control-sidebar"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                        />
                        <div className="input-group-append">
                            <button className="btn btn-sidebar">
                                <i className="fas fa-search fa-fw"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <Link to="/dashboard" className="nav-link">
                                <i className="fas fa-chart-line nav-icon"></i>
                                <p>Dashboard</p>
                            </Link>
                        </li>

                        {userType === 'Y' && (
                            <li className="nav-item">
                                <Link to="#" className="nav-link">
                                    <i className="nav-icon fa fa-th-large"></i>
                                    <p>
                                        User Details
                                        <i className="right fas fa-angle-left"></i>
                                    </p>
                                </Link>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="/manage_user" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>User Details</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}

                        <li className="nav-item">
                            <Link to="#" className="nav-link">
                                <i className="nav-icon fa fa-th-large"></i>
                                <p>
                                    Branches
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </Link>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to="/manage_branches" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Manage Branches</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <Link to="#" className="nav-link">
                                <i className="nav-icon fa fa-th-large"></i>
                                <p>
                                    Network
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </Link>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to="/manage_network" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Manage Network</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/plan_details" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Plan Details</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/billing_details" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Billing Details</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/manage_account_details" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Manage Account Details</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default Sidebar;
