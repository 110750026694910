import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Dashboard from './Dashboard.js';
import Admin from './Admin.js';
import ManageBranches from './ManageBranches';
import LoginForm from './LoginForm.js';
import Header from './Header.js';
import Sidebar from './Sidebar.js';


function App() {
  return (
    <Router>
      
        <Routes>
		  //<Route path="/login" element={<LoginForm />} />
          <Route path="/" element={<Dashboard />} />
		  
		  <Route path="/manage_branches" element={<ManageBranches />} />
          {/* You can add more routes here */}
        </Routes>
      
    </Router>
  );
}

export default App;