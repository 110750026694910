import React, { useState } from 'react';
 // Assume you have a CSS file for styling

const LoginForm = () => {
    const [formData, setFormData] = useState({ USER_ID: '', USER_PWD: '' });
    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission
        try {
            const response = await fetch('http://localhost/bill/api/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            const result = await response.json();
            if (result.success) {
                // Redirect or handle successful login
            } else {
                setResponseMessage(result.message);
            }
        } catch (error) {
            console.error('Login error:', error);
            setResponseMessage('An error occurred');
        }
    };

    return (
        <body className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                    <img src="http://183.82.10.144:40407/bill_remainder/assets/favicon.png" alt="Bill Reminder" />
                    <br />
                    <b>Bill-Reminder</b> Management
                </div>

                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Please Login To Continue..</p>

                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="BMID"
                                    name="USER_ID"
                                    value={formData.USER_ID}
                                    required
                                    onChange={handleChange}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    name="USER_PWD"
                                    placeholder="Password"
                                    value={formData.USER_PWD}
                                    required
                                    onChange={handleChange}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <button type="submit" className="btn btn-success btn-block btn-flat">Sign In</button>
                                </div>
                            </div>
                        </form>
                        {responseMessage && <p className="text-danger">{responseMessage}</p>}
                    </div>
                </div>
            </div>
        </body>
    );
};

export default LoginForm;
