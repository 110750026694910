import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// Import CSS specific to Footer

const Header = () => {
	const [formData, setFormData] = useState({
        name: ''
    });
	const [responseMessage, setResponseMessage] = useState('');
	const [token, setToken] = useState('');
	const navigate = useNavigate();
	useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch('http://localhost/ci3/api/api/profile', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Profile data:", result.profile.USER_ID);

                if (result.status) {
                    setFormData({
                        name: result.profile.USER_ID || '',
                        email: result.profile.email || '',
                        password: '' // Do not display the password
                    });
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);
	const handleLogout = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch('http://localhost/ci3/api/api/logout', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const result = await response.json();
            if (result.status) {
                localStorage.removeItem('authToken');
                setToken('');
                navigate('/login');
            }
        } catch (error) {
            console.error('Logout error:', error);
        }
    };
    return (
         <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{backgroundColor:'rgb(64 68 72)'}}>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button">
                                <i className="fas fa-bars" style={{color:'white'}}></i>
                            </a>
                        </li>
                    </ul>

                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown user user-menu">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                <img
                                    src="https://cdn-icons-png.flaticon.com/512/9385/9385289.png"
                                    className="user-image"
                                    alt="User Image"
                                />
                                <span className="hidden-xs" style={{color:'white'}}>{formData.name || ''}</span>
                            </a>
                            <ul className="dropdown-menu"     style={{margin: '0px -160px'}}>
                                <li className="user-header">
                                    <img
                                        src="https://cdn-icons-png.flaticon.com/512/9385/9385289.png"
                                        className="img-circle"
                                        alt="User Image"
                                    />
                                    <p>Admin</p>
                                </li>
                                <li className="user-footer">
                                    <div className="pull-right">
                                        <button className="btn btn-default btn-flat" onClick={handleLogout}>
                                            Sign out
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
    );
};

export default Header;